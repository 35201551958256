//
//
//
//
//
//

export default {
  middleware: 'BlockUserMiddleware',
  mounted() {
    //  this.$colorMode.preference = 'light'
  },
}
