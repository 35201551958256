//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  middleware: 'BlockUserMiddleware',
  colorMode: 'dark',
  data() {
    return {}
  },
  mounted() {
    this.$root.confirmationPopup = this.$refs.confirmationPopup
  }
}
