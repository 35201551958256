export default {
  methods: {
    danger(message, durations = 2500) {
      this.$buefy.toast.open({
        duration: durations,
        message: `${message}`,
        type: 'is-danger',
      })
    },
    success(message, durations = 2500) {
      this.$buefy.toast.open({
        duration: durations,
        message: `${message}`,
        type: 'is-success',
      })
    },
    successNotify(message) {
      this.$buefy.notification.open({
        duration: 2500,
        message,
        type: 'is-success',
        position: 'is-top-right',
        hasIcon: false,
      })
    },
    convertAndNotifyError(err) {
      if (!(err.response && err.response.data)) return
      let errors
      let errorOut = '<ul>'
      if (err.response.data.errors) {
        errors = err.response.data.errors
        for (const error in errors) {
          const element = errors[error]
          errorOut += `<li>${element[0]}</li>`
        }
      }
      errorOut += '</ul>'
      this.$buefy.notification.open({
        duration: 6000,
        message: `<b>${err.response.data.message}</b><br>${errorOut}`,
        type: 'is-danger',
        position: 'is-top-right',
        hasIcon: true,
      })
    },
    warning(message, durations = 2500) {
      this.$buefy.toast.open({
        duration: durations,
        message: `${message}`,
        type: 'is-warning',
        position: 'is-top-right',
      })
    },
  },
}
