export default function ({
  $auth,
  redirect,
  route
}) {
  // Check if user is authenticated and user details are loaded from $auth
  if ($auth.user) {
    // Check if user is not an admin and has voice_note_only attribute true
    if (!$auth.user?.is_admin && $auth.user?.extra_attribute && $auth.user.extra_attribute?.voice_note_only) {
      // Redirect to /voice-note if on any page other than /voice-note or /profile
      if (!route.path.includes('voice-note')&& !route.path.includes('profile') 
        && !route.path.includes('change-password')  && !route.path.includes('terms')
      && !route.path.includes(' booking-demo')
      ) {
        return redirect('/voice-note');
      }
    }
  }
}
