export const instanceArray = []

export function axiosIns() {
    return instanceArray.axiosIns
}
export function storeIns() {
    return instanceArray.storeIns
}

export default (axios,store) => {
    instanceArray.axiosIns = axios
    instanceArray.storeIns = store
}
