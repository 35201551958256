import { axiosIns } from '@/services/axios'
import apiResource from '@/services/service_utils'

const baseUrl = '/api/voice-notes'

export default {
  ...apiResource(baseUrl),
  chat: (payload) => axiosIns().post(`${baseUrl}/chat`, payload),
  audit: (payload) => axiosIns().post(`${baseUrl}/audit`, payload),
  transcribe: (payload) => axiosIns().post(`${baseUrl}/transcribe`, payload),
  token: () => axiosIns().get(`${baseUrl}/token`),
  save: (payload) => axiosIns().post(`${baseUrl}/save`, payload),
  updateVoiceNote: (id, payload) => axiosIns().put(`${baseUrl}/${id}/update`, payload),

  previousNote: (id) => axiosIns().get(`${baseUrl}/${id}/previous`),
  lastNote: () => axiosIns().get(`${baseUrl}/last`),

  nextNote: (id) => axiosIns().get(`${baseUrl}/${id}/next`),



  getAllActiveSnippetsOfAuthUser: (payload) => axiosIns().get(`${baseUrl}/get-all-active-snippets`),
  snippets: apiResource(`${baseUrl}/snippets`),
  templates: apiResource(`${baseUrl}/templates`),

  getChairsydeContents: (payload) => axiosIns().post(`${baseUrl}/get-chairsyde-content`,payload),
  chatV2: (payload) => axiosIns().post(`${baseUrl}/chat/v2`, payload),
}
