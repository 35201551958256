export default function ({ app, $axios }) {
  $axios.get('/sanctum/csrf-cookie')

  app.router.afterEach((to, from) => {
    if (
      [
        '/call',
        '/waiting-room/call',
        '/admin/user',
        'admin/content',
      ].reduce((acc, x) => acc || to.path.includes(x), false)) {
      document.body.classList.add('is-hidden-help')
    }
    else {
      document.body.classList.remove('is-hidden-help')
    }
  })
}
