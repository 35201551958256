//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixpanel from 'mixpanel-browser'
import AnnotationService from '@/services/modules/AnnotationService'
import Utils from '@/modules/Utils'

export default {
  data() {
    return {
      isHidden: false,
      deleted: false,
      message: 'Uploading',
      content: null,
      image: null,
    }
  },
  methods: {
    async loaderPopup(config) {

      for (const key in config) {
          this[key] = config[key]
        }
      if (this.content && this.image && this.isHidden) {
        const fileUploadResponse = await AnnotationService.fileUpload(
          Utils.b64toBlob(this.image, 'annotatedImage.png')
        )
        await AnnotationService.create({
          id: this.content ? this.content.id : 0,
          type: this.content ? this.content.type : 0,
          annotated_image_base64: fileUploadResponse.key,
          deleted: this.deleted,
        })

        if (this.content)
          mixpanel.track(
            `Annotated ${this.type === 'video' ? 'a video' : 'an image'}`,
            {
              type: this.content.type,
              name: this.content.name,
              id: this.content.id,
            }
          )
      }
      return true;
    },
  },
}
