export default function ({
  $auth,
  route,
  redirect
}) {

  if (!$auth.loggedIn) return redirect('/');

  if ($auth.loggedIn && $auth.user.signup_state === 'welcome') return redirect('/welcome');
  if ($auth.loggedIn && $auth.user.signup_state !== 'finished') return redirect('/stepper');

  // if ((route.path === '/profile')&& $auth.user.newPlan) return redirect('/');

}
