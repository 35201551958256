// middleware/auth.js
export default function ({ $auth, app, redirect }) {
  if (!$auth.loggedIn) {
    return redirect('/');
  }

  // Adjusted logic to use 'app.$can' instead of 'this.$can'
  if (!app.$can('portfolio') || ($auth.user.newPlan && ($auth.user.extra_attribute && $auth.user.extra_attribute.voice_note === true))) {
    
    return redirect('/404');
  }

  
}