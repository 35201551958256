// import apiResource from "@/services/service_utils"
import { axiosIns } from '@/services/axios'
import apiResource from '@/services/service_utils'

const baseUrl = '/api/education-content'

export default {
  getUTCTime: () => axiosIns().get(`/api/time`),

  getEdContent: () => axiosIns().get(baseUrl),
  conditions: () => axiosIns().get(`${baseUrl}/data/conditions`, { progress: false }),
  treatments: () => axiosIns().get(`${baseUrl}/data/treatments`, { progress: false }),
  treatmentCategories: () => axiosIns().get(`${baseUrl}/data/treatment-categories`, { progress: false }),
  riskCategories: () => axiosIns().get(`${baseUrl}/data/risk-categories`, { progress: false }),
  risks: () => axiosIns().get(`${baseUrl}/data/risks`, { progress: false }),
  playlist: (perPage =10) => axiosIns().get(`${baseUrl}/data/playlist?per_page=${perPage}`),

  contentFlow: (query) => axiosIns().get(`${baseUrl}/content-flow?${query}`),

  testRequest: () => axiosIns().get(`${baseUrl}/conditions-test`),

  trackTitle: (content) => axiosIns().post(`${baseUrl}/tracking/create`, {
    type: content.type,
    id: content.id
  }, { progress: false }),
  trackMedia: (content, uuid, type) => axiosIns().post(`${baseUrl}/tracking/create`, {
    type: content.type,
    id: content.id,
    uuid,
    tracking_type: type
  }, { progress: false }),
  updateTrackMediaPercentage: (id, percentage) => axiosIns().post(`${baseUrl}/tracking/update-video-playback`, {
    video_payback_percentage: percentage,
    id
  }, { progress: false }),

  getFavorites: (perPage) => axiosIns().get(`${baseUrl}/favorites?per_page=${perPage}`),
  toggleFavorites: (type, id) => axiosIns().post(`${baseUrl}/favorites`, { type, id }),
  toggleFavoritesWithSuper: (type, id, superId) => axiosIns().post(`${baseUrl}/favorites`, {
    type,
    id,
    super_id: superId
  }),

  getLogo: (type, id) => `${axiosIns().defaults.baseURL}${baseUrl}/${type}s/${id}/logo`,
  getMediaCollection: (type, id) => axiosIns().get(`${baseUrl}/${type}s/${id}/media-collection`),
  getMediaURL: (id) => axiosIns().get(`${baseUrl}/get-content/${id}`),
  getMediaThumb: (id) => axiosIns().get(`${baseUrl}/get-thumb/${id}`),

  getCondition: (conditionId) => axiosIns().get(`${baseUrl}/conditions/${conditionId}`),
  getConditions: (perPage = 15) => axiosIns().get(`${baseUrl}/conditions?per_page=${perPage}`),

  getSubCondition: (subConditionId) => axiosIns().get(`${baseUrl}/sub-conditions/${subConditionId}`),
  getSubConditions: (perPage = 15) => axiosIns().get(`${baseUrl}/sub-conditions?per_page=${perPage}`),
  getSubConditionsOfCondition: conditionId => axiosIns().get(`${baseUrl}/sub-conditions?filter[condition_id]=${conditionId}`),

  getTreatment: (treatmentId) => axiosIns().get(`${baseUrl}/treatments/${treatmentId}`),
  getTreatments: (perPage = 15) => axiosIns().get(`${baseUrl}/treatments?per_page=${perPage}`),
  getTreatmentsOfSubCondition: subConditionId => axiosIns().get(`${baseUrl}/treatments?filter[sub_condition_id]=${subConditionId}`),
  getTreatmentsOfRisk: riskId => axiosIns().get(`${baseUrl}/treatments?filter[risk_id]=${riskId}`),


  getSubTreatment: (subTreatmentId) => axiosIns().get(`${baseUrl}/sub-treatments/${subTreatmentId}`),
  getSubTreatments: (perPage = 15) => axiosIns().get(`${baseUrl}/sub-treatments?per_page=${perPage}`),
  getSubTreatmentsOfTreatment: treatmentId => axiosIns().get(`${baseUrl}/sub-treatments?filter[treatment_id]=${treatmentId}`),
  getSubTreatmentsOfRisk: riskId => axiosIns().get(`${baseUrl}/sub-treatments?filter[risk_id]=${riskId}`),


  getRisks: (perPage = 15) => axiosIns().get(`${baseUrl}/risks?per_page=${perPage}`),
  getRisksOfTreatment: treatmentId => axiosIns().get(`${baseUrl}/risks?filter[treatment_id]=${treatmentId}`),
  getRisksOfSubTreatment: subTreatmentId => axiosIns().get(`${baseUrl}/risks?filter[sub_treatment_id]=${subTreatmentId}`),

  educationContentViewTracking: apiResource(`${baseUrl}-view-tracking`),
  educationContentSortByTracking: (filters, perPage) => axiosIns().get(`${baseUrl}-sort-by-tracking?${filters}&per_page=${perPage}`),
  getFirstEdMedia: (id, type) => axiosIns().get(`${baseUrl}/get-first-media?type=${type}&id=${id}`),
  getLatestUpdatedContent: (perPage = 15, page = 1) => axiosIns().get(`${baseUrl}/get-updated-contents?per_page=${perPage}&page=${page}`)
}
