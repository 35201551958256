import IdleDetection from '@/modules/IdleDetection'

export const state = () => ({
    currentMeeting: null,
    mic: true,
    camera: true,
})

export const mutations = {
    joinMeeting(state, { meeting }) {
        state.currentMeeting = meeting
        IdleDetection.resumeDetection()
    },
    leaveMeeting(state) {
        state.currentMeeting = null
        IdleDetection.pauseDetection()
    },
    toggleMic(state) {
        state.mic = !state.mic
    },
    toggleCamera(state) {
        state.camera = !state.camera
    }
}
