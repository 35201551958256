import { axiosIns } from '@/services/axios'
import apiResource from '@/services/service_utils'

const baseUrl = '/api/patient-sessions'
const emailBaseUrl = '/api/email-summery'

export default {
  ...apiResource(baseUrl),
  create: async (patientName) => {
    let payload = {
      name: patientName
    };

    if (window.$platform !== undefined &&
        window.$platform.current_patient !== undefined &&
        window.$platform.current_patient.name &&
        window.$platform.current_patient.email) {
      payload = {
        name: window.$platform.current_patient.name,
        email: window.$platform.current_patient.email,
        patient_id: window.$platform.current_patient.patient_id,
        platform: window.$platform.current_patient.platform
      }
    }

    if (Object.keys(payload).length === 0 && payload.constructor === Object) {
        const platform = localStorage.getItem('platform');
        payload.platform = platform;
    }
    return await axiosIns().post(baseUrl, payload)
  },
  createsession: (payload) => axiosIns().post(`${baseUrl}/create-session`, payload),
  createWithSOE: (payload) => axiosIns().post(`${baseUrl}/create-with-soe`, payload),
  createWithPlatform: (payload) => axiosIns().post(`${baseUrl}/create-with-platform`, payload),
  createWithoutTracking: (payload) => axiosIns().post(`${baseUrl}/create-without-tracking`, payload),
  getTrackingContents: (sessionId, page = 1) => axiosIns().get(`/api/education-content/tracking?filter[patient_session_id]=${sessionId}&page=${page}&append=tracking_media`),
  getTrackingContentsForCopy: (sessionId) => axiosIns().get(`/api/education-content/tracking?filter[patient_session_id]=${sessionId}&per_page=10000`),
  ongoingSession: () => axiosIns().get(`${baseUrl}/ongoing`),
  stopSession: (sessionId) => axiosIns().post(`${baseUrl}/${sessionId}/stop1`),
  getSessionDates: (start, end, type, searchKey, params) => axiosIns().get(`${baseUrl}/month-session-count?start=${start}&end=${end}&type=${type}&name=${searchKey}&${params}`),
  getPatientSessionByUuid: (uuid) => axiosIns().get(`${baseUrl}/uuid/${uuid}`),
  getSOEPatient: (SOEPatientId, SOEPracticeId) => axiosIns().get(`api/get-soe-patient?soe_patient_id=${SOEPatientId}&soe_practice_id=${SOEPracticeId}`),
  email: {
    ...apiResource(emailBaseUrl),
    getEmailContents: (emailId) => axiosIns().get(`${emailBaseUrl}/${emailId}/get-email-content`),
    addContents: (emailId, content) => axiosIns().post(`${emailBaseUrl}/${emailId}/add-content`, content),
    addAttachments: (emailId, content) => axiosIns().post(`${emailBaseUrl}/${emailId}/add-attachment`, content),
    updateAttachments: (emailId, content) => axiosIns().post(`${emailBaseUrl}/${emailId}/update-attachment`, content),
    removeAttachments: (emailId, content) => axiosIns().post(`${emailBaseUrl}/${emailId}/remove-attachment`, content),
    removeContents: (emailId) => axiosIns().delete(`${emailBaseUrl}/content/${emailId}`),
    updateContentOrder: (emailId, order) => axiosIns().post(`${emailBaseUrl}/content/${emailId}/update-order`, { order }),
    updateContentPrice: (emailId, price) => axiosIns().post(`${emailBaseUrl}/content/${emailId}/update-price`, { price }),
    addAnnotation: (emailId, annotationId) => axiosIns().post(`${emailBaseUrl}/${emailId}/add-annotation`, { annotation_id: annotationId }),
    removeAnnotation: (emailId, annotationId) => axiosIns().post(`${emailBaseUrl}/${emailId}/remove-annotation`, { annotation_id: annotationId }),
    sendEmail: (emailId, withConsent = 0) => axiosIns().post(`${emailBaseUrl}/${emailId}/send?withConsent=${withConsent}`),
    sendSms: (emailId, withConsent = 0) => axiosIns().post(`${emailBaseUrl}/${emailId}/send-sms?withConsent=${withConsent}`)
  }
}
