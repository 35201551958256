//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      modalShow: false,
      dark: true,
      haveClose: true,
      canCancel: ['escape', 'outside'],
      resolve: null,
      reject: null,
      cancelText: null,
      okText: null,
      description: null,
      title: null,
      titleClass: null,
      descriptionClass: null,
      isLoading: false,
      haveLoading: false,
    }
  },
  methods: {
    confirmPopup(config) {
      this.modalShow = true
      this.isLoading = false
      this.haveLoading = false
      for (const key in config) {
        this[key] = config[key]
      }
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    onCancelClick() {
      this.resolve(false)
      this.modalShow = false
    },
    stopLoading() {
      this.isLoading = false
      this.modalShow = false
    },
    onOkClick() {
      if (this.haveLoading) {
        this.isLoading = true
        this.resolve(this)
      } else {
        this.resolve(true)
        this.modalShow = false
      }
    },
  },
}
