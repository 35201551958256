import { render, staticRenderFns } from "./light.vue?vue&type=template&id=621c8018&scoped=true&"
import script from "./light.vue?vue&type=script&lang=js&"
export * from "./light.vue?vue&type=script&lang=js&"
import style0 from "./light.vue?vue&type=style&index=0&id=621c8018&scoped=true&lang=css&"
import style1 from "./light.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "621c8018",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SidebarLight: require('/codebuild/output/src1962734579/src/v3-UI/components/SidebarLight.vue').default,ConfirmationLightPopup: require('/codebuild/output/src1962734579/src/v3-UI/components/ConfirmationLightPopup.vue').default,MainAnnotationLoader: require('/codebuild/output/src1962734579/src/v3-UI/components/Main/AnnotationLoader.vue').default})
