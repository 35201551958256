import mixpanel from 'mixpanel-browser'


export default function({ $config }) {
  mixpanel.init($config.mixpanelToken, {
    debug: false,
    ignore_dnt: true,
    batch_size: 1,
    persistence: 'localStorage',
    api_host: 'https://mixpanel.chairsyde.com'
  })
}
