export default async function ({
  store,
  next,
  $axios,
  redirect,
}) {
  try {
    if (store.state.countyAllow === null) {
      const countryData = (await $axios.$get('/api/user/location'))
      store.commit('addCountyAllow', countryData.data, )
    }

    const allowCountry = store.state.countyAllow

    if (!allowCountry) {
      return redirect('/unavailable');
    }

    return next();
  } catch (e) {

  }
}
