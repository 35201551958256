export default async function ({
  store,
  next,
  $axios,
  redirect,
  $i18n
}) {
  try {
    if (store.state.countyAllow === null) {
      store.commit('addCountyAllow',
        (await $axios.$get('/api/user/location')).data,
      )
    }

    const allowCountry = store.state.countyAllow
    if (allowCountry) {
      return redirect('/');
    }

    return next();
  } catch (e) {
    console.error(e);
  }
}
