import { RealtimeTranscriber } from 'assemblyai/dist/assemblyai.umd'
import VueAssembly from '@/plugins/vue-assemblyai'
import VoiceNoteService from '@/services/modules/VoiceNoteService'

export const state = () => ({
  textPlacements: {},
  liveTranscribeText: '',
  assemblyAIToken: null,
  averageAudio: 0,
  recording: false,
  barTimer: null,
  isTokenLoading: false,
  audioData : {},
  showOverlay : true,
  timer: null,
  barValues: [],
  mediaRecorder: null,
  duration: 0,
  rt: null,
  mc: null,
  currentTemplate: '',
  currentTemplateType: '',
  currentVoiceNoteId:null,
  currentPatientName: '',
  isProcessingVoiceNote: false,
  recordingResumed:false,
  microphonePermissionGranted: false,
  currentGeneratedNote:'',
  existingVoiceNotes : [],
  existingTemplates : [],
  customTemplateTitle: '',
  patientSelectedFromDropdown : false,
  startNewNote : false,
  timerForAsseblyAITempTokenReFetch: null
})

export const getters = {
  getPatientSelectedFromDropdown(state){
    return state.patientSelectedFromDropdown
  },
  getStartNewNote(state){
    return state.startNewNote
  },
  getExistingTemplates(state){
    return state.existingTemplates
  },
  getCurrentGeneratedNote(state){
    return state.currentGeneratedNote
 },
  getExistingVoiceNotes(state){
    return state.existingVoiceNotes
  },
  getMicrophonePermissionGranted(state){
    return state.microphonePermissionGranted;
  },
  getResumeRecording(state){
    return state.recordingResumed;
  },
  getAssemblyAIToken(state){
    return state.assemblyAIToken;
  },
  getCurrentVoiceNoteId(state){
    return state.currentVoiceNoteId;
  },
  getCurrentTemplateType(state){
    return state.currentTemplateType;
  },
  getShowOverlay(state) {
    return state.showOverlay;
  },
  getRecording(state) {
    return state.recording;
  },
  getBarValues(state) {
    return state.barValues;
  },
  getIsTokenLoading(state) {
    return state.isTokenLoading;
  },
  getLiveTranscribeText(state) {
    return state.liveTranscribeText;
  },
  getFormattedDuration(state) {
    const secNum = parseInt(state.duration, 10)
    let hours = Math.floor(secNum / 3600)
    let minutes = Math.floor((secNum - hours * 3600) / 60)
    let seconds = secNum - hours * 3600 - minutes * 60

    if (hours < 10) {
      hours = '0' + hours
    }
    if (minutes < 10) {
      minutes = '0' + minutes
    }
    if (seconds < 10) {
      seconds = '0' + seconds
    }
    return hours + ':' + minutes + ':' + seconds
  },
  getCurrentTemplate(state) {
    return state.currentTemplate;
  },
  getCurrentPatientName(state) {
    return state.currentPatientName;
  },
  getIsProcessingVoiceNote(state) {
    return state.isProcessingVoiceNote;
  },
  getCustomTemplateTitle(state) {
    return state.customTemplateTitle;
  }
};
export const mutations = {
  SET_START_NOTE(state){
    state.patientSelectedFromDropdown = false
    setTimeout(() => {state.patientSelectedFromDropdown = true},1000)
  },
  SET_PATIENT_SELECT_FROM_DROPDOWN(state, value){
    state.patientSelectedFromDropdown = value
  },
  SET_EXISTING_TEMPLATES(state, templates = []){
    state.existingTemplates = templates
  },
  SET_EXISTING_VOICE_NOTES(state, notes = []){
    state.existingVoiceNotes = notes
  },
  SET_CURRENT_GENERATED_NOTE(state, note){
    state.currentGeneratedNote = note
  },
  SET_CURRENT_VOICE_NOTE_ID(state, id){
    state.currentVoiceNoteId = id
  },
  SET_MICROPHONE_PERMISSION_GRANTED(state, value){
    state.microphonePermissionGranted = value
  },
  SET_RECORDING_RESUMED(state, value){
    state.recordingResumed = value
  },
  SET_RT(state, value) {
    state.rt = value;
  },
  SET_CLOSE_REALTIME_TRANSCRIBER(state) {
    if (state.rt.socket && state.rt.socket.readyState === WebSocket.OPEN) {
      state.rt.close()
    }
  },
  SET_MC(state, value) {
    state.mc = value;
  },
  SET_MC_STOP_RECORDING(state) {
    state.mc.stopRecording()
  },
  SET_RECORDING(state, status) {
    state.recording = status;
  },
  SET_OVERLAY(state, status) {
    state.showOverlay = status;
  },
  SET_IS_TOKEN_LOADING(state, status) {
    state.isTokenLoading = status;
  },
  SET_AVERAGE_AUDIO(state, averageAudio) {
    state.averageAudio = averageAudio;
  },
  SET_AUDIO_DATA(state, audioData) {
    state.audioData = audioData;
  },
  UPDATE_LIVE_TRANSCRIBE_TEXT(state, text) {
    state.liveTranscribeText = text;
  },
  UPDATE_TEXT_PLACEMENTS(state, { key, text }) {
    state.textPlacements[key] = text;
  },
  RESET_TEXT_PLACEMENTS(state, textPlacements) {
    state.textPlacements = textPlacements;
  },
  SET_BAR_TIMER(state, timer) {
    state.barTimer = timer;
  },
  SET_TIMER(state, timer) {
    state.timer = timer;
  },
  SET_MEDIA_RECORDER(state, mediaRecorder) {
    state.mediaRecorder = mediaRecorder;
  },
  SET_BAR_VALUES(state, value) {
    state.barValues = value;
  },
  SET_DURATION(state, value) {
    state.duration = value;
  },
  SET_ASSEMBLY_AI_TOKEN(state, value) {
    state.assemblyAIToken = value;
  },
  SET_CURRENT_TEMPLATE(state, value) {
    state.currentTemplate = value;
  },
  SET_CURRENT_TEMPLATE_TYPE(state, value) {
    state.currentTemplateType = value;
  },
  SET_CURRENT_PATIENT_NAME(state, value) {
    state.currentPatientName = value;
  },
  SET_IS_PROCESSING_VOICE_NOTE(state, value) {
    state.isProcessingVoiceNote = value
  },
  SET_CUSTOM_TEMPLATE_TITLE(state, value) {
    state.customTemplateTitle = value
  },
  SET_TOKEN_REFRESH_TIMER(state, value) {
    state.timerForAsseblyAITempTokenReFetch = value
  }
};

export const actions = {
  startTokenRefreshTimer({ commit, dispatch,state }) {
    if (!state.timerForAsseblyAITempTokenReFetch) {
      const timer = setInterval(() => {
        dispatch('getAssemblyAIToken');
      },  35700 * 1000); // 9 hours and 55 minutes

      commit('SET_TOKEN_REFRESH_TIMER', timer);
    }
  },
  stopTokenRefreshTimer({ commit, state }) {
    if (state.timerForAsseblyAITempTokenReFetch) {
      clearInterval(state.timerForAsseblyAITempTokenReFetch);
      commit('SET_TOKEN_REFRESH_TIMER', null);
    }
  },
  async createVoiceNote({ commit, state },data={}){
    try {
      const payload = {...{
        type:'default',
        patient_name: state.currentPatientName
      },...data}
      const response = (await VoiceNoteService.save(payload)).data.data;

      commit('SET_CURRENT_VOICE_NOTE_ID', response.id);
      commit('SET_CURRENT_PATIENT_NAME', response.patient_name);
    } catch (error) {}
  },
  changeOverlayState({ commit },value) {
    commit('SET_OVERLAY',value);
  },
  async requestMicrophonePermission({ dispatch, commit, state }) {
    let permissionGranted = false
    await navigator.mediaDevices
      .getUserMedia({
        audio: {
          noiseSuppression: true,
          echoCancellation: true
        },
        video: false
      })
      .then((stream) => {

        if (!state.currentVoiceNoteId) {
          dispatch('createVoiceNote');
        }

        if (this.$config.transcribeProvider === 'assemblyAI') {
          dispatch('startRecordingWithAssemblyAI');
        } else {
          dispatch('startRecordingWithOpenAI', stream);
        }
        commit('SET_MICROPHONE_PERMISSION_GRANTED', true)
        permissionGranted = true
      })
      .catch(() => {
        commit('SET_MICROPHONE_PERMISSION_GRANTED', false)
        commit('SET_IS_PROCESSING_VOICE_NOTE', false)
        alert(
          'Microphone access is required to record audio. Please enable it and try again.'
        )
      })

    return permissionGranted
  },
  onChangeTranscribeInput({ commit, state,dispatch,rootGetters },text) {
    commit('UPDATE_LIVE_TRANSCRIBE_TEXT', text);
  },
  async startRecordingWithAssemblyAI({ commit, state,dispatch }) {
    commit('SET_IS_PROCESSING_VOICE_NOTE', true);
    commit('SET_IS_TOKEN_LOADING', true);
    commit('UPDATE_TEXT_PLACEMENTS', { key: -1, text: state.liveTranscribeText });
    try {

      const mc = VueAssembly()
      const rt = new RealtimeTranscriber({
        token: state.assemblyAIToken,
        sampleRate: 48000,
      });

      await commit('SET_MC', mc);
      await commit('SET_RT', rt);

      rt.on('transcript', (message) => {
        let msg = '';
        commit('UPDATE_TEXT_PLACEMENTS', { key: message.audio_start, text: message.text });
        const keys = Object.keys(state.textPlacements);
        keys.sort((a, b) => a - b);
        for (const key of keys) {
          if (state.textPlacements[key]) {
            msg += ` ${state.textPlacements[key]}`;
          }
        }
        commit('UPDATE_LIVE_TRANSCRIBE_TEXT', msg);
      });

      rt.on('error', () => {
        dispatch('stopRecording');
      })

      rt.on('close', () => {
        dispatch('stopRecording');
      })

      await rt.connect()

      await mc.startRecording((audioData, audio) => {
        rt.sendAudio(audioData)
        commit('SET_AVERAGE_AUDIO', audio);
      })

      dispatch('handleRecording',true);
      const timer = setInterval(() => {
        dispatch('onBarTick');
      }, 50);
      commit('SET_BAR_TIMER', timer);

      commit('SET_RECORDING', true);
      setTimeout(() => {
        commit('SET_IS_PROCESSING_VOICE_NOTE', false);
      },500)
    } catch (error) {
      commit('SET_IS_TOKEN_LOADING', false);
      commit('SET_IS_PROCESSING_VOICE_NOTE', false);
      alert(
        'Microphone access is required to record audio. Please enable it and try again.'
      )
    }
    commit('SET_IS_TOKEN_LOADING', false);
  },
  async startRecordingWithOpenAI({ commit, dispatch }, stream) {
    commit('SET_IS_TOKEN_LOADING', true);
    commit('SET_RECORDING', true);
    dispatch('handleRecording', true);

    const ctx = new AudioContext();
    const mic = ctx.createMediaStreamSource(stream);
    const analyser = ctx.createAnalyser();
    mic.connect(analyser);

    const audioData = {
      ctx,
      analyser,
      stream,
    };
    commit('SET_AUDIO_DATA', audioData);

    dispatch('play');

    await commit('SET_TIMER', setInterval(() => {
      dispatch('onTick');
    }, 7000))

    await commit('SET_BAR_TIMER', setInterval(() => {
      dispatch('onBarTick');
    }, 50));

    await dispatch('onTick');
    commit('SET_IS_TOKEN_LOADING', false);
  },
  stopRecording({ commit,state,dispatch }) {
    commit('SET_RECORDING', false);
    dispatch('handleRecording',false);
    clearInterval(state.barTimer);
  },

  voiceNoteStoreClear({ commit, state, dispatch }) {
    commit('SET_RECORDING_RESUMED', false)
    commit('SET_CURRENT_PATIENT_NAME', '')
    commit('SET_CURRENT_GENERATED_NOTE', '')
    commit('RESET_TEXT_PLACEMENTS', {})
    commit('UPDATE_LIVE_TRANSCRIBE_TEXT', '')
    commit('SET_DURATION', 0)
    dispatch('onChangeTranscribeInput', '')
    commit('SET_CURRENT_VOICE_NOTE_ID', null)
    dispatch('changeOverlayState', true)
    dispatch('voice-note/remote-mic/leavePusherChannel',{},{root:true})
    dispatch('setCurrentTemplate', {
      template: '',
      type: 'default'
    })
    dispatch('stopTokenRefreshTimer')
    dispatch('stopRecording')
  },

  onBarTick({commit, state }) {
    if (!state.recording) return
    const barValues = [...state.barValues];
    if (barValues.length > 20) {
      barValues.shift();
    }
    const rangeValue = state.averageAudio > 70 ? 70 : 5 + state.averageAudio
    barValues.push(rangeValue);
    commit('SET_BAR_VALUES', barValues);
    commit('SET_DURATION', state.duration + 50 / 1000);
  },

  onTick({ state, commit, dispatch  }) {
    const { stream } = state.audioData
    if (!stream || !state.recording) return

    if (state.mediaRecorder && state.mediaRecorder.state !== 'inactive') {
      state.mediaRecorder.stop()
    }

    const mediaRecorder = new MediaRecorder(stream);
    mediaRecorder.addEventListener('dataavailable', (event) => {
      dispatch('transcribeWithOpenAI', event);
    });
    mediaRecorder.start();
    commit('SET_MEDIA_RECORDER', mediaRecorder);
  },

  play({ state,commit,dispatch }) {
    const { analyser, ctx } = state.audioData
    if (!(analyser || ctx)) return

    const data = new Uint8Array(analyser.frequencyBinCount)
    analyser.getByteFrequencyData(data)

    let total = 0
    for (let j = 0; j < analyser.frequencyBinCount; j++) {
      total += data[j]
    }
    commit('SET_AVERAGE_AUDIO', total / analyser.frequencyBinCount);

    requestAnimationFrame(() => dispatch('play'));
  },
  async transcribeWithOpenAI({ state, commit, dispatch,rootGetters }, event) {
     const formData = new FormData();
    formData.append(
      'audio',
      new File([event.data], 'user_audio.webm', { type: 'audio/webm' })
    );
    formData.append('extension', 'webm');
    formData.append('old_text', state.liveTranscribeText);

    try {
      const response = (await VoiceNoteService.transcribe(formData)).data.data;
      commit('UPDATE_LIVE_TRANSCRIBE_TEXT',
        `${state.liveTranscribeText} ${response.text}`.trim()
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error transcribing audio:', error);
    }
  },
  async handleRecording({ state, commit, dispatch }, value) {
    if (!value) {
      if (this.$config.transcribeProvider === 'assemblyAI') {
        if (state.rt) {
         await commit('SET_CLOSE_REALTIME_TRANSCRIBER');
         await commit('SET_RT', null);
        }

        if (state.mc) {
         await commit('SET_MC_STOP_RECORDING');
         await commit('SET_MC', null);
        }

        if (state.barTimer) clearInterval(state.barTimer);
      } else {
        clearInterval(state.timer);
        clearInterval(state.barTimer);
        if (state.mediaRecorder) {
          state.mediaRecorder.stop();
        }
        state.audioData.stream.getTracks().forEach((track) => {
          track.stop();
        });
        commit('setAverageAudio', 0);
        commit('setDuration', 0);
        commit('setAudioData', {});
      }
    }
  },
  async getAssemblyAIToken({commit,rootGetters}) {
    commit('SET_IS_TOKEN_LOADING', true);
    try {
      const token = (await VoiceNoteService.token()).data.data.token
      commit('SET_ASSEMBLY_AI_TOKEN', token);
      if(rootGetters['voice-note/remote-mic/getPusherChannel']){
        commit('voice-note/remote-mic/SET_PUSHER_AUTH', {
          success: true,
          token: rootGetters['voice-note/getAssemblyAIToken']
        },{root:true})
      }
    } catch (error) {
    }
    commit('SET_IS_TOKEN_LOADING', false);
  },
  setCurrentTemplate({ commit },value) {
    commit('SET_CURRENT_TEMPLATE', value.template);
    commit('SET_CURRENT_TEMPLATE_TYPE', value.type)
  }
};


