import Vue from 'vue'
import VuePlyr from 'vue-plyr/dist/vue-plyr.ssr.js'
import 'plyr/dist/plyr.css'

const controls = `
<div class='plyr__controls pb-4 px-5-tablet'>
    <div class='columns m-0 mr-5 p-0 flex-grow progress-bar' style='flex-grow:1'>
        <div class='column m-0 p-0'>
            <div class='plyr__progress'>
                <input data-plyr='seek' type='range' min='0' max='100' step='0.01' value='0' aria-label='Seek'>
                <progress class='plyr__progress__buffer' min='0' max='100' value='0'>% buffered</progress>
                <span role='tooltip' class='plyr__tooltip'>00:00</span>
            </div>
            <div class='columns is-mobile m-0 p-0'>
                <div class='column p-0 is-narrow'>
                    <div style='font-size: 10px;' class='plyr__time plyr__time--current' aria-label='Current time'>00:00</div>
                </div>
                <div class='column p-0'> </div>
                <div class='column p-0 mr-2 is-narrow'>
                    <div style='font-size: 10px;' class='plyr__time plyr__time--speed speed' aria-label='Speed'>00:00</div>
                </div>
                <div class='column p-0 is-narrow'>
                    <div style='font-size: 10px;' class='plyr__time plyr__time--duration' aria-label='Duration'>00:00</div>
                </div>
            </div>
        </div>
    </div>


    <div class='mb-4 mr-2 thumb is-32x32 is-rounded has-background-ch-maroon plyr__control prv-control' onClick="window.plyerEmit(this,'prv')">
        <span class='icon has-text-white icon--not-pressed' style='position:unset'><i class='mdi mdi-skip-previous mdi-18px'></i></span>
        <span class='plyr__tooltip' role='tooltip'>Previous media</span>
    </div>
    <div class='mb-4 mr-2 thumb is-32x32 is-rounded has-background-ch-maroon plyr__control' data-plyr='rewind'>
        <span class='icon has-text-white icon--not-pressed' style='position:unset'><i class='mdi mdi-rewind mdi-18px'></i></span>
        <span class='plyr__tooltip' role='tooltip'>Rewind {seektime} secs</span>
    </div>
    <div class='mb-4 mr-2 thumb is-32x32 is-rounded has-background-ch-maroon plyr__control' aria-label='Play, {title}' data-plyr='play'>
        <span class='icon has-text-white icon--pressed' style='position:unset'><i class='mdi mdi-pause mdi-18px'></i></span>
        <span class='icon has-text-white icon--not-pressed' style='position:unset'><i class='mdi mdi-play mdi-18px'></i></span>

        <span class='label--pressed plyr__tooltip' role='tooltip'>Pause</span>
        <span class='label--not-pressed plyr__tooltip' role='tooltip'>Play</span>
    </div>
    <div class='mb-4 mr-2 thumb is-32x32 is-rounded has-background-ch-maroon plyr__control' data-plyr='fast-forward'>
        <span class='icon has-text-white icon--not-pressed' style='position:unset'><i class='mdi mdi-fast-forward mdi-18px'></i></span>
        <span class='plyr__tooltip' role='tooltip'>Forward {seektime} secs</span>
    </div>
    <div class='mb-4 mr-2 thumb is-32x32 is-rounded has-background-ch-maroon plyr__control' onClick="window.plyerEmit(this,'slower')">
        <span class='icon has-text-white icon--not-pressed' style='position:unset'><i class='mdi mdi-speedometer-slow mdi-18px'></i></span>
        <span class='plyr__tooltip' role='tooltip'>Slow Down</span>
    </div>
    <div class='mb-4 mr-2 thumb is-32x32 is-rounded has-background-ch-maroon plyr__control' onClick="window.plyerEmit(this,'faster')">
        <span class='icon has-text-white icon--not-pressed' style='position:unset'><i class='mdi mdi-speedometer mdi-18px'></i></span>
        <span class='plyr__tooltip' role='tooltip'>Speed Up</span>
    </div>
    <div class='mb-4 mr-2 thumb is-32x32 is-rounded has-background-ch-maroon plyr__control next-control' onClick="window.plyerEmit(this,'next')">
        <span class='icon has-text-white icon--not-pressed' style='position:unset'><i class='mdi mdi-skip-next mdi-18px'></i></span>
        <span class='plyr__tooltip' role='tooltip'>Next Media</span>
    </div>
      <div class='mb-4 mr-2 thumb is-32x32 is-rounded has-background-ch-maroon plyr__control' onClick="window.plyerEmit(this,'fullScreen')">
        <span class='icon has-text-white icon--not-pressed' style='position:unset'> <i class='fas fa-lg fa-expand-alt mdi-18px'></i>
        <span class='plyr__tooltip' role='tooltip'>Full Screen</span>
    </div>


</div>
<div class='middleButtons is-flex is-justify-content-space-between'>
    <div class='plyr__control' data-plyr='rewind' onClick="window.plyerEmit(this,'back10')">
        <i class='mdi mdi-rewind-10 mdi-48px'></i>
    </div>
    <button type='button' class='plyr__control play-icon' onClick="window.plyerEmit(this,'playPause')">
        <i id='icon' class='mdi mdi-play mdi-48px'></i>
    </button>
    <div class='plyr__control' data-plyr='fast-forward' onClick="window.plyerEmit(this,'forward10')">
        <i class='mdi mdi-fast-forward-10 mdi-48px'></i>
    </div>
</div>
`

//    <div class="mb-4 thumb is-32x32 is-rounded has-background-ch-maroon plyr__control" data-plyr="settings">
//     <span class="icon has-text-white icon--not-pressed" style="position:unset"><i class="mdi mdi-cog mdi-18px"></i></span>
// </div>

Vue.use(VuePlyr, {
  plyr: {
    // ratio: '4:3',
    // iconUrl: require('../src/assets/video-play-icon.png'),
    // controls: ['play-large', 'progress'],
    // controls: [],
    storage: {
      enabled: false,
      key: 'plyr'
    },
    hideControls: true,
    controls,
    fullscreen: {
      enabled: false
    },
  },
})
