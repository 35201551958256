//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MixpanelMixins from '@/mixins/MixpanelMixins'
export default {
  mixins: [MixpanelMixins],
  data() {
    return {
      modalShow: false,
      joyride: [
        {
          title: 'Getting started',
          videos: [
            {
              title: '01. Getting started',
              videos: [
                {
                  id: 'XtwXGCzA5PX2z4mZJsntz8',
                  time: '01:31',
                },
              ],
            },
            {
              title: '02. Consult your patients with gum disease',
              videos: [
                {
                  id: '6iDzoBMGLditTq6TecJr7Z',
                  time: '01:58',
                },
              ],
            },
            {
              title: '03. Example of an orthodontic consulting on Chairsyde',
              videos: [
                {
                  id: '7xyKoRiruNdaJmpvPBk4SX',
                  time: '02:06',
                },
              ],
            },
            {
              title: '04. How to search for content',
              videos: [
                {
                  id: 'qH56M2udybWUjfy5TZ11aj',
                  time: '00:16',
                },
              ],
            },
            {
              title: '05. Annotations on Chairsyde',
              videos: [
                {
                  id: 'AtGrcTemuYHVps8vg42spS',
                  time: '00:52',
                },
              ],
            },
            {
              title: '06. Sending an email to patients on Chairsyde',
              videos: [
                {
                  id: 'R3HJFRjFAVfVSMFw7vtWAy',
                  time: '01:05',
                },
              ],
            },
            {
              title: '07. How to view your tracking details',
              videos: [
                {
                  id: 'V3k3HnZUhf5ui4Cg58kQUn',
                  time: '00:27',
                },
              ],
            },
          ],
        },
        {
          title: 'Managing your account',
          videos: [
            {
              title: '01. Setting favorites',
              videos: [
                {
                  id: 'tez5S7zZ1FPgkkDENsi9CU',
                  time: '00:10',
                },
              ],
            },
            {
              title: '02. How to create your portfolio',
              videos: [
                {
                  id: 'hTj8cSqYdxVih2L5YRagwi',
                  time: '00:28',
                },
              ],
            },
          ],
        },
      ],
    }
  },
  // mounted() {
    // document.querySelectorAll(`[data-uuid]`).forEach((player) => {
    //   window.VidyardV4.api.renderPlayer(player).then((result) => {
    //     result.on('play', () => {
    //       this.stopVideo(result.uuid)
    //     })
    //   })
    // })
  // },
  methods: {
    open() {
      this.trackOnMixpanel('Help button clicked')
      this.modalShow = true
    },
    close() {
      this.modalShow = false
      this.stopVideo('')
    },
    stopVideo(currentUuid) {
      window.VidyardV4.players.forEach((player) => {
        if (currentUuid !== player.uuid) {
          player.pause()
        }
      })
    },
  },
}
