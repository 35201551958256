export default function ({
  $auth,
  route,
  redirect
}) {
  const limitedRoutes = ['voice-note', 'profile', 'profile-subscription'];

  if ($auth.loggedIn && $auth.user.voicePlan) {
    const currentRoute = route.name;
    if (!limitedRoutes.includes(currentRoute)) {
      return redirect('/voice-note');
    }
  }

}
