//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import omit from 'lodash/omit'
// import mixpanel from 'mixpanel-browser'
import moment from 'moment/src/moment'
import MeetingService from '@/services/modules/MeetingService'
import SessionService from '@/services/modules/SessionService'
import AnalyticsInterface from '@/constants/mixpanel/AnalyticsInterface'

const {
  ConsultationModeList,
  logConsultationCompleted,
  logConsultationStarted,
} = AnalyticsInterface

export default {
  data() {
    return {
      participant_count: 0,
      meeting: null,
      meetingTimer: null,
      url: '',
      notes: [],
      note: '',
      isNoteLoading: false,
      isLoading: false,
      mobileView: false,
    }
  },
  watch: {
    'meeting.id': {
      handler() {
        this.fetchNote()
      },
      deep: true,
    },
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage)
    this.$root.$on('CSYD:idleDetected', this.idleDetected)
    this.meetingTimer = setInterval(() => {
      this.meeting = this.$store.state.meeting.currentMeeting
      if (this.meeting) {
        const query = {
          audio: this.$store.state.meeting.mic ? 'on' : 'off',
          video: this.$store.state.meeting.camera ? 'on' : 'off',
          floatSelf: 'on',
          breakout: 'off',
          pipButton: 'off',
          logo: 'off',
          background: 'off',
          timer: 'off',
          metadata: window.location.host,
          displayName:
            this.$auth.user.first_name + ' ' + this.$auth.user.last_name,
        }
        const queryData = []
        for (const key in query) {
          queryData.push(`${key}=${encodeURIComponent(query[key])}`)
        }
        this.url = `${this.meeting.where_by_host_meeting_link}&${queryData.join(
          '&'
        )}`
      }
    }, 100)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage)
    this.$root.$off('CSYD:idleDetected', this.idleDetected)
    clearInterval(this.meetingTimer)
  },
  methods: {
    idleDetected() {
      if (this.participant_count < 2) {
        this.$store.commit('meeting/leaveMeeting')
        if (window.location.pathname.includes('waiting-room/call')) {
          this.$router.push('/waiting-room')
        }
      }
    },
    async fetchNote() {
      this.isLoading = true
      try {
        const response = (await MeetingService.note(this.meeting.id).index())
          .data.data
        this.notes = response.map((x) => ({
          ...x,
          created_at: moment.utc(x.created_at).local().format('h:mm a'),
        }))
      } catch (error) {
        this.handleError(error)
      }
    },
    async addNote(note) {
      this.isNoteLoading = true
      try {
        const response = (
          await MeetingService.note(this.meeting.id).create({ note })
        ).data.data
        this.notes.push({
          ...response,
          created_at: moment.utc(response.created_at).local().format('h:mm a'),
        })
        this.note = ''
        this.$nextTick(() => {
          const messageDisplay = this.$refs.noteElement
          messageDisplay.scrollTo({
            top: messageDisplay.scrollHeight,
            behavior: 'smooth',
          })
        })
      } catch (error) {
        this.convertAndNotifyError(error)
      }
      this.isNoteLoading = false
    },
    async receiveMessage(event) {
      if (event.origin !== 'https://chairsyde.whereby.com') {
        return
      }

      const data = event.data

      const session = this.$store.state.session.currentSession

      switch (data.type) {
        case 'participantupdate':
          this.participant_count = data.payload.count
          if (data.payload.count === 1) {
            window.lastActiveTime = new Date()
          }
          if (this.participant_count > 1) {
            logConsultationStarted(ConsultationModeList.Virtual)
            // mixpanel.track(`Join the meeting`, {
            //   type: this.meeting.is_pms === 1 ? 'PMS' : 'Normal',
            //   ...omit(this.meeting, ['name'])
            // })
          }
          break
        case 'leave':
          this.$store.commit('meeting/leaveMeeting')
          this.$router.replace('/waiting-room')
          await MeetingService.endActive()
          if (session) {
            const sessionData = await SessionService.stopSession(session.id)
            const startDate = new Date(sessionData.start)
            const endDate = new Date(sessionData.end)

            const durationSeconds = (endDate - startDate) / 1000
            logConsultationCompleted(
              ConsultationModeList.Virtual,
              durationSeconds
            )
          }
          break
        default:
          break
      }
    },
    viewNote() {
      if (this.mobileView) {
        this.mobileView = false
      } else {
        this.mobileView = true
      }
    },
  },
}
