import VoiceNoteService from '@/services/modules/VoiceNoteService'

let pusherChannel = null

export const state = () => ({
  qrQueryToken: Array(3).fill().map(() => Math.random().toString(36).substring(2)).join('').substring(0, 36),
  remoteMic: null,
  cloeQrPopup: false,
  minimizeTemplateOverlay :false,
  recordingStartedViaRemoteDevice : false
})

export const getters = {
  getPusherChannel() {
    return pusherChannel
  },
  getMinimizeTemplateOverlay(state) {
    return state.minimizeTemplateOverlay
  },
  getRemoteMic(state) {
    return state.remoteMic
  },
  getQrQueryToken(state) {
    return state.qrQueryToken
  },
  getCloeQrPopup(state) {
    return state.cloeQrPopup
  },
  getRecordingStartedViaRemoteDevice(state) {
    return state.recordingStartedViaRemoteDevice
  }
}
export const mutations = {
  SET_RECORDING_STARTED_VIA_REMOTE_DEVICE(state,value) {
    state.recordingStartedViaRemoteDevice = value
  },
  SET_CLOSE_QR_POPUP(state,close = true) {
    state.cloeQrPopup = close
  },
  SET_MINIMIZE_TEMPLATE_OVERLAY(state,value) {
    state.minimizeTemplateOverlay = value
  },
  SET_REMOTE_MIC(state, value) {
    state.remoteMic = value
  },
  SET_PUSHER_AUTH(state, payload) {
    pusherChannel.whisper('auth', payload)
  }
}
export const actions = {
  joinPusher({ state, commit, dispatch }) {
    pusherChannel = this.$echo.join(`VoiceNote.${state.qrQueryToken}`)
      .joining((value) => dispatch('onMicJoin', value))
      .leaving((value) => dispatch('onMicLeave', value))
      .listenForWhisper('startRecordingAtWebPage', (value) => dispatch('startRecordingAtWebPage', value))
      .listenForWhisper('stopRecordingAtWebPage', (value) => dispatch('stopRecordingAtWebPage', value))
      .listenForWhisper('transcript', (value) => dispatch('onTranscript', value))
      .listenForWhisper('barValues', (values) => commit('voice-note/SET_BAR_VALUES', values, { root: true }))
      .listenForWhisper('notifyWebBrowserToToggleLoaderState', (value) => commit('voice-note/SET_IS_PROCESSING_VOICE_NOTE', value.loading, { root: true }))
      .listenForWhisper('notifyWebBrowserToStartNewNote', (value) => commit('voice-note/SET_START_NOTE', value, { root: true }))
  },
  async stopRecordingAtWebPage({ rootGetters,commit ,dispatch}, value) {
    commit('voice-note/SET_IS_PROCESSING_VOICE_NOTE',true,{ root: true })
    pusherChannel.whisper('recordingStoppedAtWebBrowser',{})
    commit('voice-note/SET_RECORDING_RESUMED', !value?.is_a_new_note, { root: true })
    commit('voice-note/SET_RECORDING', false, { root: true })
    dispatch('voice-note/handleRecording', false, { root: true })

    if(rootGetters['voice-note/getLiveTranscribeText'] !== ''){
      commit('SET_MINIMIZE_TEMPLATE_OVERLAY',true)
      const saveObject = {
        type: 'default',
        transcript: rootGetters['voice-note/getLiveTranscribeText']
      }
      if(rootGetters['voice-note/getCurrentVoiceNoteId']){
        await VoiceNoteService.updateVoiceNote(rootGetters['voice-note/getCurrentVoiceNoteId'], saveObject)
      } else {
        dispatch('voice-note/createVoiceNote', saveObject, { root: true })
      }
      setTimeout(() =>  commit('SET_MINIMIZE_TEMPLATE_OVERLAY',false),5000)
    }
    commit('voice-note/SET_IS_PROCESSING_VOICE_NOTE',false,{ root: true })
  },
  onTranscript({ commit }, { liveTranscribeText, duration }) {
    commit('voice-note/UPDATE_LIVE_TRANSCRIBE_TEXT', liveTranscribeText, { root: true })
  },
  updateTranscriptAtRemoteDevice({ commit }, text) {
    if(pusherChannel){
      pusherChannel.whisper('updateTranscriptAtRemoteDevice',{liveTranscribeText:text})
    }
  },
  startRecordingAtWebPage({ commit, dispatch }) {
    commit('SET_RECORDING_STARTED_VIA_REMOTE_DEVICE',true)
    commit('voice-note/RESET_TEXT_PLACEMENTS', {}, { root: true })
    commit('voice-note/SET_RECORDING_RESUMED', false , { root: true })
    commit('voice-note/UPDATE_TEXT_PLACEMENTS', { key: -1, text: this.liveTranscribeText }, { root: true })
    commit('voice-note/SET_RECORDING', true, { root: true })
    commit('SET_MINIMIZE_TEMPLATE_OVERLAY',false)

    const timer = setInterval(() => {
      dispatch('voice-note/onBarTick', null, { root: true })
    }, 50);

    commit('voice-note/SET_BAR_TIMER', timer,{ root: true });
    pusherChannel.whisper('notifyRemoteDeviceToStopLoaders',{})
    commit('voice-note/SET_IS_PROCESSING_VOICE_NOTE',false,{ root: true })
  },
  onMicJoin({ state, commit, rootGetters }, user) {
    if (!state.remoteMic) {
      commit('SET_REMOTE_MIC', { id: user.id })
      commit('SET_PUSHER_AUTH', {
        success: true,
        token: rootGetters['voice-note/getAssemblyAIToken']
      })
    }
    commit('SET_CLOSE_QR_POPUP')
    setTimeout(() =>commit('SET_CLOSE_QR_POPUP',false) ,3000)
  },
  onMicLeave({ state, commit ,rootGetters, dispatch}, user) {
    if (state.remoteMic && state.remoteMic.id === user.id) {
      commit('SET_REMOTE_MIC', null)
      if(rootGetters['voice-note/getRecording']){
        dispatch('stopRecordingAtWebPage')
      }
    }
  },
  startTranscribingAtRemoteDevice(){
    pusherChannel.whisper('startTranscribingAtRemoteDevice',{})
  },
  stopTranscribingAtRemoteDevice({commit },isANewNote = false){
    pusherChannel.whisper('stopTranscribingAtRemoteDevice',{is_a_new_note:isANewNote})
  },
  resumeTranscribingAtRemoteDevice(){
    pusherChannel.whisper('resumeTranscribingAtRemoteDevice',{})
  },
  resetStateAtRemoveDevice(){
    pusherChannel?.whisper('resetStateAtRemoveDevice',{})
  },
  setExistingTranscribingTextAtRemoteDevice({ rootGetters}){
    pusherChannel?.whisper('setExistingTranscribingTextAtRemoteDevice',{text:rootGetters['voice-note/getLiveTranscribeText']})
  },
  leavePusherChannel({state},value){
    this.$echo.leave(`VoiceNote.${state.qrQueryToken}`)
  },
}
