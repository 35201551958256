import { axiosIns } from '@/services/axios'
import apiResource from '@/services/service_utils'

const baseUrl = '/api/meetings'

export default {
    ...apiResource(baseUrl),
    getWaitingRoom: filters => axiosIns().get(`${baseUrl}/waiting-room?${filters}`),
    getActive: () => axiosIns().get(`${baseUrl}/active`),
    endActive: () => axiosIns().post(`${baseUrl}/end-active`),
    invite: (payload) => axiosIns().post(`${baseUrl}/invite`, payload),
    cancel: (meetingId) => axiosIns().post(`${baseUrl}/${meetingId}/cancel`),
    notify: (meetingId, note) => axiosIns().post(`${baseUrl}/${meetingId}/notify`, { note }),
    note: (meetingId) => apiResource(`${baseUrl}/${meetingId}/note`),
}