//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    inputClass: {
      type: String,
      default: ''
    },
    inputStyle: {
      type: String,
      default: ''
    },
    fontSize: {
      type: Number,
      default: 1.75
    },
    maxLength: {
      type: Number,
      default: 30
    },
    placeholder: {
      type: String,
      default: 'Patient name'
    }
  },
  data() {
    return {
      focused: false,
      localValue: this.value,
      width: 0,
      initialWidth: 0,
      timer: null
    }
  },
  computed: {
    inputWidth() {
      if (this.$slots.default) return this.localValue.length * 1 + 'rem'
      else return this.localValue.length * 0.68 + 'rem'
    },
    hasDefaultName() {
      return !!this.value.match(/(Patient [0-9]+)/g)
    }
  },
  watch: {
    value(newValue) {
      this.localValue = newValue
      this.width = 0
      this.$nextTick(() => {
        if (this.$refs.input) {
          this.width = this.$refs.input.scrollWidth
          this.initialWidth = this.$refs.input.scrollWidth
        }
      })
    }
  },
  mounted() {
    this.width = 0
    this.$nextTick(() => {
      this.width = this.$refs.input.scrollWidth
      this.initialWidth = this.$refs.input.scrollWidth
    })
  },
  methods: {
    updateWidth() {
      this.width = 0
      this.$nextTick(() => {
        if (this.$refs.input) {
          const newWidth = this.$refs.input.scrollWidth
          if (newWidth > this.initialWidth) {
            this.width = newWidth
          } else {
            this.width = this.initialWidth
          }
        }
      })
    },
    focus() {
      this.focused = true
      this.localValue = this.value.match(/(Patient [0-9]+)/g) ? '' : this.value
      this.$refs.input.focus()
    },
    blur() {
      this.focused = false
      if (this.localValue !== '' && this.localValue !== this.value)
        this.$emit('input', this.localValue)
      else this.localValue = this.value
      this.width = 0
      this.$nextTick(() => {
        if (this.$refs.input) this.width = this.$refs.input.scrollWidth
      })
    },
    updateValue() {
      this.focused = false
    }
  }
}
