export const state = () => ({
  deviceType: getComputedStyle(document.documentElement).getPropertyValue('--deivce-type'),
  contentCache: {},
  contentData: {},
  countyAllow: null,
  isStopCurrentSession: false,
  isVoiceNoteInProgress: false,
  platform: "",
})

export const mutations = {
  add(state, {
    key,
    content
  }) {
    state.contentCache[key] = content
    content.forEach(element => {
      state.contentData[`${element.type}_${element.id}`] = element
      const subContent = []
      if (element.type === 'condition') {
        element.sub_conditions.forEach((subElement) => {
          state.contentData[`${element.type}_${subElement.type}_${subElement.id}`] = subElement
        })
        subContent.push(...element.sub_conditions)
      } else if (element.type === 'treatment-category') {
        const mappedObject = element.content_categories.map(contentCategory => ({
          ...contentCategory,
          id: contentCategory.categorizable_id,
          name: contentCategory.content_name,
          type: contentCategory.content_type
        }))
        mappedObject.forEach((subElement) => {
          state.contentData[`${element.type}_${subElement.type}_${subElement.id}`] = subElement
        })
        subContent.push(...mappedObject)
      }else if (element.type === 'risk-category') {
        const mappedObject = element.content_categories.map(contentCategory => ({
          ...contentCategory,
          id: contentCategory.categorizable_id,
          name: contentCategory.content_name,
          type: contentCategory.content_type
        }))
        mappedObject.forEach((subElement) => {
          state.contentData[`${element.type}_${subElement.type}_${subElement.id}`] = subElement
        })
        subContent.push(...mappedObject)
      } else if (element.type === 'treatment') {
        element.risks.forEach((subElement) => {
          state.contentData[`${element.type}_${subElement.type}_${subElement.id}`] = subElement
        })
        element.sub_treatments.forEach((subElement) => {
          state.contentData[`${element.type}_${subElement.type}_${subElement.id}`] = subElement
        })
        subContent.push(...element.risks)
        subContent.push(...element.sub_treatments)
      } else if (element.type === 'risk') {
        element.sub_treatments.forEach((subElement) => {
          state.contentData[`${element.type}_${subElement.type}_${subElement.id}`] = subElement
        })
        element.treatments.forEach((subElement) => {
          state.contentData[`${element.type}_${subElement.type}_${subElement.id}`] = subElement
        })
        subContent.push(...element.sub_treatments)
        subContent.push(...element.treatments)
      }else if (element.type === 'playlist') {
        element.sub_treatments.forEach((subElement) => {
          state.contentData[`${element.type}_${subElement.type}_${subElement.id}`] = subElement
        })
        element.treatments.forEach((subElement) => {
          state.contentData[`${element.type}_${subElement.type}_${subElement.id}`] = subElement
        })
        subContent.push(...element.sub_treatments)
        subContent.push(...element.treatments)
      }
      state.contentData[`${element.type}_${element.id}`].options = subContent
    })
  },
  addNew(state, {
    key,
    content
  }) {
    state.contentCache[key] = content
    content.forEach(element => {
      element.content_categories.forEach(newElement => {
        state.contentData[`${newElement.content_type}_${newElement.content_id}`] = {
          id: newElement.content_id,
          name: newElement.content_name,
          type: newElement.content_type,
          content_logo: newElement.content_logo
        }
      })
    })
  },
  remove(state, {
    key
  }) {
    delete state.contentCache[key]
  },
  clear(state) {
    state.contentCache = {}
    state.contentData = {}
  },
  updateDeviceType(state) {
    state.deviceType = getComputedStyle(document.documentElement).getPropertyValue('--deivce-type')
  },
  addCountyAllow(state, content) {
    state.countyAllow = content
  },
  toggleIsVoiceNoteInProgress(state) {
    state.isVoiceNoteInProgress = !state.isVoiceNoteInProgress
  },
  updateStopCurrentSessionState(state, value) {
    localStorage.setItem('isStopCurrentSession', JSON.stringify(value))
    state.isStopCurrentSession = value
  },
}
