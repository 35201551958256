const mixpanel = require("mixpanel-browser");

class AnalyticsManager {
  static track(object, action, properties = {}) {
    try {
      const eventName = `${object} ${action}`;
      mixpanel.track(eventName, properties);
    } catch (error) {
      console.error("Error tracking event:", error);
    }
  }

  static trackScreen(screen, properties = {}) {
    try {
      const eventName = `${screen} Viewed`;
      mixpanel.track(eventName, properties);
    } catch (error) {
      console.error("Error tracking screen:", error);
    }
  }
}
module.exports = AnalyticsManager;
