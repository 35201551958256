//
//
//
//
//
//

export default {
  middleware: 'NotBlockUserMiddleware',
  colorMode: 'light',
  mounted() {
    // this.$colorMode.preference = 'light'
  },
}
