import VoiceNoteService from '@/services/modules/VoiceNoteService'

export const state = () => ({
  activeSnippets: [],
  isFetchActiveSnippetsInProgress:false
})

export const getters = {
  getActiveSnippets(state) {
    return state.activeSnippets;
  },
  getIsFetchActiveSnippetsInProgress(state) {
    return state.isFetchActiveSnippetsInProgress;
  }
};
export const mutations = {
  SET_IS_FETCH_ACTIVE_SNIPPETS_IN_PROGRESS(state, status) {
    state.isFetchActiveSnippetsInProgress = status;
  },
  SET_ACTIVE_SNIPPETS(state, values) {
    state.activeSnippets = values;
  },
}
export const actions = {
  async getAllActiveSnippetsOfAuthUser({ commit }) {
    commit('SET_IS_FETCH_ACTIVE_SNIPPETS_IN_PROGRESS',true);
    const activeSnippets = (
      await VoiceNoteService.getAllActiveSnippetsOfAuthUser()
    ).data.data
    commit('SET_ACTIVE_SNIPPETS',activeSnippets);
    commit('SET_IS_FETCH_ACTIVE_SNIPPETS_IN_PROGRESS',false);
  },
}
