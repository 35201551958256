//
//
//
//
//
//
//

// import IdleDetection from '@/modules/IdleDetection'

export default {
    props: {
        account: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            status: 'idle',
            idleDetection: false,
            timer: null
        }
    },
    mounted() {
        this.timer = setInterval(() => {
            this.idleDetection = window.idleDetection
            if (window.lastActiveTime)
                if ((new Date().getTime() - window.lastActiveTime.getTime()) > 1000 * 60) {
                    this.status = 'is-idle'
                } else {
                    this.status = 'is-active'
                }
        }, 10)
    },
    beforeDestroy() {
        clearInterval(this.timer)
    },
}
