export default {
    timerKey: 0,
    detectTime: 5 * 60 * 1000,
    app: null,
    init(app) {
        window.addEventListener('mousemove', this.triggerMove)
        window.addEventListener('mousedown', this.trigger)
        window.addEventListener('keypress', this.trigger)
        this.app = app
        window.idleDetection = false
        this.startTimer()
        window.idleId = (Math.random() + 1).toString(36).substring(2)
    },
    trigger() {
        window.lastActiveTime = new Date()
    },
    triggerMove(event) {
        if (event.movementX > 1 || event.movementY > 1)
            window.lastActiveTime = new Date()
    },
    pauseDetection() {
        window.idleGraceTime = 0
        window.idleDetection = false
    },
    resumeDetection() {
        window.idleGraceTime = 0
        window.lastActiveTime = new Date()
        window.idleDetection = true
    },
    startTimer() {
        this.timerKey = setInterval(() => {
            if (!window.idleDetection) return
            const currentTime = new Date()
            if (!window.lastActiveTime) {
                window.lastActiveTime = new Date()
            }
            if ((currentTime.getTime() - window.lastActiveTime.getTime()) > this.detectTime) {
                window.idle = true
                if (window.idleGraceTime && window.idleGraceTime > 0) {
                    window.idleGraceTime -= 1
                } else {
                    this.app.$emit('CSYD:idleDetected')
                }
            } else {
                window.idle = false
            }
        }, 1000)
    },
    clearTimer() {
        clearInterval(this.timerKey)
    }
}