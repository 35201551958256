
import Vue from 'vue'
import SessionService from '@/services/modules/SessionService'
class PlatformConnector {
    constructor($auth, $store,$root) {
        this.$auth = $auth
        this.$store = $store
        this.requestQueue = {}
        this.urlBase = Vue.observable(false)
        this.invalidPatient = Vue.observable(false)
        this.connected = Vue.observable(false)
        this.current_patient = Vue.observable({
            name: null,
            email: null,
            patientId: null
        })
        this.handler = async (event) => {
            if (!event.data.type) return
            if (event.data.type === 'request' && event.data.requestId) {
                const action = event.data.action
                this.connected = true
                window.parent.postMessage({
                    type: "response",
                    requestId: event.data.requestId,
                    data: await this['request' + action](event.data)
                }, "*");
            }
            if (event.data.type === 'response' && event.data.requestId) {
                const requestObject = this.requestQueue[event.data.requestId]
                clearTimeout(requestObject.timer)
                requestObject.resolve(event.data)
            }
        }
        window.addEventListener('message', this.handler)
        $auth.$storage.watchState('loggedIn', async (newValue, oldValue) => {
            if (!newValue && oldValue && this.connected) {
                await this.userLogout()
            }
        })
        const self = this;
        $store.watch((state) => state.session, async () => {
            const currentPatient = await this.requestGetCurrentSession()
            if (currentPatient) {
                self.invalidPatient = currentPatient.patient_id !== this.current_patient.patient_id
            } else {
                self.invalidPatient = false
            }
            await this.sessionChanged()
        }, { deep: true });
        const currentUrl = new URL(window.location);
        this.urlBase = !!currentUrl.searchParams.get($auth.ctx.query.platform);
        if (this.urlBase) {
            try {
                const urlData = currentUrl.searchParams.get($auth.ctx.query.platform);
                const [name, email, id] = urlData.split(":");
                if (id.length !== 36) throw new Error('Invalid Id');
                this.current_patient.name = name
                this.current_patient.email = email
                this.current_patient.patient_id = id
            } catch (error) {
                this.urlBase = false;
            }
        }
    }

    async requestRefreshCurrentAuthStatus() {
        localStorage.setItem('auth.strategy', 'laravelSanctum')
        localStorage.setItem('auth._token.laravelSanctum', 'true')
        localStorage.setItem('auth._token_expiration.laravelSanctum', 'false')
        await this.$auth.loginWith('laravelSanctum', {
            data: {
                email: 'something',
                password: 'something',
                remember: true,
            },
        })
        const styleElement = document.createElement('style')
        styleElement.innerHTML = ".grecaptcha-badge{display:none !important}"
        document.body.append(styleElement)
        return null;
    }

    requestGetCurrentSession() {
        return this.$store.state.session.currentSession
    }

    requestGetCurrentAuthStatus() {
        return {
            user: this.$auth.user,
            loggedIn: this.$auth.loggedIn
        }
    }

    requestGetCurrentUrl(eventData) {
        return window.location.href
    }

    requestSetCurrentPatient(eventData) {
        this.current_patient.name = eventData.name
        this.current_patient.email = eventData.email
        this.current_patient.patient_id = eventData.patient_id
        return true;
    }

    async requestStopCurrentSession(eventData) {
        await SessionService.stopSession(this.$store.state.session.currentSession.id)
        return true;
    }

    async sessionChanged() {
        await this.getData({
            action: 'SessionChanged',
            data: this.$store.state.session.currentSession
        })
        return true
    }

    async userLogout() {
        await this.getData({
            action: 'UserLogout'
        })
        return true
    }

    getData(data) {
        if (!this.connected) return;
        return new Promise((resolve, reject) => {
            data.type = 'request'
            data.requestId = (Math.random() + 1).toString(36).substring(7)
            const payload = {
                resolve,
                timer: setTimeout(() => {
                    this.requestQueue[data.requestId] = null
                    delete this.requestQueue[data.requestId]
                    reject(new Error(`Connection Timeout (Request - ${data.requestId})`))
                }, 3000)
            }
            this.requestQueue[data.requestId] = payload
            window.parent.postMessage(data, "*")
        })
    }
}

export default function (context, inject) {
    const platformConnector = new PlatformConnector(context.$auth, context.store, context.app);
    window.$platformConnector = platformConnector
    inject('platform', platformConnector)
}