import { axiosIns } from '@/services/axios'
import apiResource from '@/services/service_utils'
import VaporFileUpload from '@/modules/VaporFileUpload'

const baseUrl = '/api/annotations'

export default {
    ...apiResource(baseUrl),
    index: filters => axiosIns().get(`${baseUrl}?${filters}`),
    indexWithoutDeleted: filters => axiosIns().get(`${baseUrl}?filter[withDeleted]=false&${filters}`),
    delete: (id) => axiosIns().post(`${baseUrl}/${id}/temp-delete`, {}),
    fileUpload: (file) => VaporFileUpload.store(axiosIns(), `${axiosIns().defaults.baseURL}/api/upload`, file, {
        visibility: `private`,
        withCredentials: true,
    })
}