export default function ({
  $auth,
  redirect,
  route
}) {
  const user = $auth.user;

  if (user && !user.is_admin && !user.voicePlan) {
    const extra = user.extra_attribute;

    const noVoiceNote =
      extra === null || extra && (

        !('voice_note' in extra) || !extra.voice_note
      ) && (
        !('voice_note_only' in extra) || !extra.voice_note_only
      );

    if (noVoiceNote && route.path.includes('voice-note')) {
      return redirect('/landing');
    }
  }
}
