import Vue from 'vue'

import { confirmed, email, length, required } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

extend('required', {
  ...required,
  message: 'This field is required'
})

extend('email', {
  ...email,
  message: 'This field must be a valid email'
})

extend('confirmed', {
  ...confirmed,
  message: 'This field confirmation does not match'
})

extend('different_password', {
  params: ['target'],
  validate(value, { target }) {
    return value !== target
  },
  message: 'The new password is the same as your current password. Please enter a different password.'
})

extend('length', {
  ...length,
  message: 'This field must have 2 options'
})

extend('phone_number', {
  validate: value => value.match(/^[+0-9]{8,20}$[+0-9]*/),
  message: 'Enter a valid phone number '
})

extend('phone_number_without_country', {
  validate: value => value.match(/^(\+[\d]{1,5}|0)?[7-9]\d{9}$/),
  message: 'Enter a valid phone number.'
})

extend('youtube_url', {
  validate: value => value.match(/^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/),
  message: 'Enter a valid url '
})

extend('password', value => {
  if (value.length < 8) return 'Password must be at least 8 characters'
  if (value.match(/[a-z]/) == null) return 'Password must contain at least one lowercase character'
  if (value.match(/[A-Z]/) == null) return 'Password must contain at least one uppercase character'
  if (value.match(/[0-9]/) == null) return 'Password must contain at least one number'
  if (value.match(/[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]/) == null) return 'Password must contain at least one special character'
  return true
})
