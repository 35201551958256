//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {}
  },
  computed: {
    currentSession() {
      return this.$store.state.session.currentSession
    },
  },
}
