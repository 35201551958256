import {
  axiosIns
} from '@/services/axios'
// import apiResource from '@/services/service_utils'

const baseUrl = '/api/user'

export default {
  updatePassword: (data) => axiosIns().put(`user/password`, data),
  getAnnouncements: () => axiosIns().get(`${baseUrl}/announcement`),
  dftUserFirst: () => axiosIns().get(`${baseUrl}/dft-user`),

  getPaymentMethod: () => axiosIns().get(`${baseUrl}/payment-method`),
  addPaymentMethod: (payload) => axiosIns().post(`${baseUrl}/payment-method`, payload),
  removePaymentMethod: () => axiosIns().delete(`${baseUrl}/payment-method`),
  updateTerms: () => axiosIns().post(`${baseUrl}/update-terms`),
  getToken: (payload) => axiosIns().post(`${baseUrl}/token`, payload),
}
