import Vue from 'vue';

import ErrorMessageMixins from '@/mixins/ErrorMessageMixins'

Vue.mixin(ErrorMessageMixins)

Vue.component('TransitionHeight', {
    name: 'ExpandHeight',
    functional: true,
    render(createElement, context) {
        const transitionAttr = {
            props: {
                name: 'expand-height'
            },
            on: {
                enter(ele) {
                    const width = getComputedStyle(ele).width
                    ele.style.transition = 'none'
                    ele.style.position = 'absolute'
                    ele.style.visibility = 'hidden'
                    ele.style.height = ''
                    ele.style.width = width

                    const height = getComputedStyle(ele).height

                    ele.style.position = ''
                    ele.style.visibility = ''
                    ele.style.width = ''
                    ele.style.height = 0

                    // force dom re-render
                    // getComputedStyle(ele).height

                    requestAnimationFrame(() => {
                        ele.style.transition = ''
                        ele.style.height = height
                    })
                },
                afterEnter(ele) {
                    ele.style.height = ''
                },
                leave(ele) {
                    const height = getComputedStyle(ele).height
                    ele.style.height = height

                    requestAnimationFrame(() => {
                        ele.style.height = '0'
                    })
                }
            }
        }
        return createElement('transition', transitionAttr, context.children)
    }
})

Vue.filter("price", function (value, fixed = 0) {
    return (value / 100).toFixed(fixed)
});

Vue.filter("capitalize", function (value) {
    if (!value)
        return '';
    return value.split('-').map(value => value.charAt(0).toUpperCase() + value.slice(1)).join(' ').toString()
});

Vue.filter("cutText", function (value, length, suffix) {
    if (!value) return '';
    if (value.length > length) {
        return value.substring(0, length) + suffix;
    } else {
        return value;
    }
});

window.plyerEmit = function (element, eventType) {
    const plyr = element.parentElement.parentElement
    const event = new CustomEvent(eventType, {
        bubbles: true,
        detail: {

        },
    });
    plyr.dispatchEvent(event)
}