import mixpanel from 'mixpanel-browser';
import axios from '@/services/axios';

export default function ({ $auth, router, $axios, store }, inject) {
  axios($axios, store)

  setInterval(() => {
    $axios.get('/sanctum/csrf-cookie')
  }, 1 * 60 * 1000);

  let skip = false;
  $auth.$storage.watchState('loggedIn', (newValue, oldValue) => {
    localStorage.setItem('loggedIn', newValue);
    localStorage.setItem('loggedInOld', oldValue);
    skip = true
  })

  const currentAuthUser = $auth.state.user
  if (currentAuthUser) {
    mixpanel.identify(currentAuthUser.email);
    mixpanel.people.set({ ...currentAuthUser, '$name': `${currentAuthUser.first_name} ${currentAuthUser.last_name}`, '$email': currentAuthUser.email })
  }

  $auth.$storage.watchState('user', newValue => {
    if (newValue) {
      mixpanel.identify(newValue.email);
      mixpanel.people.set({ ...newValue, '$name': `${newValue.first_name} ${newValue.last_name}`, '$email': newValue.email })
    } else {
      store.commit('meeting/leaveMeeting')
    }
  })

  window.addEventListener('resize', (event) => {
    store.commit('updateDeviceType')
  })
  store.commit('updateDeviceType')

  setInterval(() => {
    if (localStorage.getItem('loggedIn') !== localStorage.getItem('loggedInOld') && !skip) {
      localStorage.setItem('loggedInOld', localStorage.getItem('loggedIn'));
      window.location.reload();
    }
  }, 100);

  $axios.onError(error => {
    if (error && error.response && error.response.status === 401) {
      $auth.reset()
      // window.location.reload();
      router.push('/login')
    }
  })
}
